/**
 * This is the MDX component for tables.
 */
const TableWrapper = ({ children }) => {
  return (
    <>
      {/* Wrappper of entire table */}
      <table className="table-auto text-body16 font-sans font-normal m-5 w-5/6 mx-auto">{children}</table>
    </>
  )
}

const TableHeaderWrapper = ({ children }) => {
  return (
    <>
      {/* Wrapper of header. */}
      <thead className="bg-lysgronn30-300 font-axiformaSemibold text-left border-b-2 border-gray-300">{children}</thead>
    </>
  )
}

const TableHeader = ({ children }) => {
  return (
    <>
      {/* Header Items  */}
      <th className="px-2 py-2">{children}</th>
    </>
  )
}

const TableBody = ({ children }) => {
  return (
    <>
      {/* Wrappper */}
      <tbody className="text-left border-b-2 border-gray-300">{children}</tbody>
    </>
  )
}

const TableRow = ({ children }) => {
  return (
    <>
      {/* Wrappper */}
      <tr>{children}</tr>
    </>
  )
}

const TableCell = ({ children }) => {
  return (
    <>
      {/* Wrappper */}
      <td className="px-2 py-2 border-2 border-gray-100">{children}</td>
    </>
  )
}

export { TableWrapper, TableHeaderWrapper, TableHeader, TableBody, TableRow, TableCell }
