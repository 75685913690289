import axios from "axios"
import { AuthToken } from "../Security/authToken"

export const closeNotification = async (setNotification) => {
  setNotification(() => ({
    showNotification: false,
  }))
}

export const fetchApi = async (url, account, inProgress, instance) => {
  const accessToken = await AuthToken(account, inProgress, instance)

  return axios.get(url, {
    headers: {
      "Conent-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      "Ocp-Apim-Subscription-Key": process.env.API_KEY,
    },
  })
}

export const fetchApiAnonymous = async (url) => {
  const response = axios.get(url, {
    headers: {
      "Conent-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.API_KEY,
    },
  })

  return response
}

export const fetchApiDownload = async (url, account, inProgress, instance) => {
  const accessToken = await AuthToken(account, inProgress, instance)

  return axios.get(url, {
    headers: {
      "Conent-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      "Ocp-Apim-Subscription-Key": process.env.API_KEY,
    },
    responseType: "blob",
  })
}

export const postApi = async (url, obj, account, inProgress, instance) => {
  const accessToken = await AuthToken(account, inProgress, instance)

  const response = axios.post(url, obj, {
    headers: {
      "Conent-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      "Ocp-Apim-Subscription-Key": process.env.API_KEY,
    },
  })

  return response
}

export const postApiAnonymous = async (url, obj) => {
  const response = axios.post(url, obj, {
    headers: {
      "Conent-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.API_KEY,
    },
  })

  return response
}

export const patchApi = async (url, obj, account, inProgress, instance) => {
  const accessToken = await AuthToken(account, inProgress, instance)

  const response = axios.patch(url, obj, {
    headers: {
      "Conent-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      "Ocp-Apim-Subscription-Key": process.env.API_KEY,
    },
  })

  return response
}

export const patchApiAnonymous = async (url, obj) => {
  const response = axios.patch(url, obj, {
    headers: {
      "Conent-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.API_KEY,
    },
  })

  return response
}

export const deleteApi = async (url, account, inProgress, instance) => {
  const accessToken = await AuthToken(account, inProgress, instance)

  const response = axios.delete(url, {
    headers: {
      "Conent-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      "Ocp-Apim-Subscription-Key": process.env.API_KEY,
    },
  })

  return response
}

export const deleteApiAnonymous = async (url) => {
  const response = axios.delete(url, {
    headers: {
      "Conent-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.API_KEY,
    },
  })

  return response
}

export const fetchDownLoadFileApi = async (url, obj, account, inProgress, instance) => {
  const accessToken = await AuthToken(account, inProgress, instance)

  const response = axios.post(url, obj, {
    headers: {
      "Conent-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      "Ocp-Apim-Subscription-Key": process.env.API_KEY,
    },
  })

  return response
}
