const Parallax = ({ image, ...props }) => {
  // Make sure we have at least one image
  const bgimage =
    image ||
    "https://res.cloudinary.com/gartnerhallen/image/upload/c_crop,dpr_auto,f_webp,g_center,q_auto,w_auto/v1/Tema/Fremtidens%20gr%C3%B8ntproduksjon/6_1_banzz3"

  const value = "url(" + bgimage + ")"

  return (
    <>
      <section>
        <div className="box-border">
          <div className="parallax-wrapper">
            {props.children.map((child, index) =>
              child.props.mdxType === "ParallaxItem" ? (
                <>
                  <div
                    key={index}
                    className="container mx-auto flex items-center justify-items-center h-screen min-h-screen bg-fixed bg-cover bg-center"
                    style={{ backgroundImage: value, backgroundAttachment: "fixed" }}
                  >
                    <div className="grid justify-items-center min-w-full">
                      <p className="p-6 my-14 text-header24 text-black bg-lysgronn30-300 bg-opacity-90 max-w-max self-center">
                        {child.props.children}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    key={index}
                    className="container mx-auto flex items-center justify-items-center h-screen bg-fixed bg-cover bg-center"
                    style={{ backgroundImage: value }}
                  >
                    <div className="grid justify-items-center min-w-full">
                      <child.type {...child.props} key={index}></child.type>
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default Parallax
