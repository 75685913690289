import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/vsDark"

const PrismCodeBlock = (props) => {
  const className = props.children.props.className
  const language = className?.replace(/language-/, "") || ""

  return (
    <Highlight {...defaultProps} code={props.children.props.children.trim()} language={language} theme={theme}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => {
        return (
          <>
            {/* Container */}
            <div className="relative">
              {/* Add space for Code tab */}
              {/* Code Tab     right: 5%;  */}
              <div className="absolute bg-black text-tomat-500 uppercase top-0 right-48 text-sm font-bold -translate-y-full p-1 rounded-t-md rounded-l-md">
                {language}
              </div>
              <div className="bg-black font-mono text-xs rounded-sm px-1 py-1 mx-1 my-1 overflow-x-scroll">
                <pre className={className} style={style}>
                  {tokens.map((line, i) => (
                    <div key={i} {...getLineProps({ line, key: i })}>
                      {line.map((token, key) => (
                        <span key={key} {...getTokenProps({ token, key })} />
                      ))}
                    </div>
                  ))}
                </pre>
              </div>
            </div>
          </>
        )
      }}
    </Highlight>
  )
}

export default PrismCodeBlock
