import { TypographyLink } from "./Style"

export const TypographyBlockQuoteShort = "text-squash-500 text-xl"
export const TypographyBlockQuoteLong = "font-sans font-normal italic text-body14 text-black" // blockquote >

const Wrapper = (props) => {
  return <div className=" py-6  my-6  border-solid  text-body18 m-8" {...props} />
}

export const MdxRenderTypographyQuoteLong = (props) => <p className={TypographyBlockQuoteLong} {...props} />

const Blockquote = ({ children, display }) => {
  // Check if children is a string
  const child = typeof children === "string" || children instanceof String ? replaceLink(children.toString()) : children

  switch (display) {
    case "warning":
      return (
        <Wrapper>
          <blockquote className="text-sort-200 border-4 border-tomat-500 py-20 sm:px-40 px-6 sm:py-12 relative">
            {/* .warning { background: #fffaeb; color: #513c06; border-color: #f7d070;.icon {  color: #f7d070;  } */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className=" absolute  left-2/4  top-8 sm:top-16 sm:left-24   transform -translate-x-2/4 -translate-y-2/4 w-10 h-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              textAnchor="middle"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            {child}
          </blockquote>
        </Wrapper>
      )

    case "info":
      return (
        <Wrapper>
          <blockquote className=" border-knapplysgronn-500 border-2 py-20 sm:px-40 px-6 sm:py-12 relative ">
            {/* background: var(--clr-primary-10); color: var(--clr-primary-1);   border-color: var(--clr-primary-5);  .icon {     color: var(--clr-primary-5);  */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className=" absolute  left-2/4  top-8 sm:top-16 sm:left-24   transform -translate-x-2/4 -translate-y-2/4 w-10 h-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {child}
          </blockquote>
        </Wrapper>
      )

    case "default":
      return <blockquote className="font-sans font-normal italic text-body18 px-8 text-sort-200">{child}</blockquote>

    default:
      return <blockquote className="font-sans font-normal italic text-body18 px-8 text-sort-200">{child}</blockquote>
  }
}

/**
 * Replaces all [text](link) with <a href={link}>text</a>.
 */
const replaceLink = (str: string) => {
  // used to find text with format [text](link)
  const fullRegex = /\[\S*\]\([^\)]*\)/g
  // gets the [text] part
  const textRegex = /\[\S*\]/
  // gets the (link) part
  const linkRegex = /\([^\)]*\)/

  // applyes the regex
  const links = Array.from(str.matchAll(fullRegex), (m) => ({
    text: m[0].match(textRegex)[0],
    link: m[0].match(linkRegex)[0],
  }))
  const text = str.split(fullRegex)

  if (links.length === 0) return <div>{str}</div>

  return (
    <div>
      {text[0]}
      {links.map((link, index) => {
        return (
          <>
            <a className={TypographyLink} href={link.link.substring(1, link.link.length - 1)}>
              {link.text.substring(1, link.text.length - 1)}
            </a>
            {text[index + 1]}
          </>
        )
      })}
    </div>
  )
}

export default Blockquote
