export const TypographyHeading1 = "text-header24 font-sans  text-sort-200 font-bold pb-8 pt-9"
export const TypographyHeading2 = "text-header18 font-Axiforma normal text-sort-200 pb-6 pt-6" // h2
export const TypographyHeading3 = "text-header16 font-Axiforma normal text-sort-200 pb-6 pt-6" // h3
export const TypographyHeading4 = "text-header16 font-Axiforma normal text-sort-200 pb-6 pt-6" // h4

export const MdxRenderTypographyH1 = (props) => <h2 className={TypographyHeading1} {...props} />
export const MdxRenderTypographyH2 = (props) => <h3 className={TypographyHeading2} {...props} />
export const MdxRenderTypographyH3 = (props) => <h4 className={TypographyHeading3} {...props} />
export const MdxRenderTypographyH4 = (props) => <h5 className={TypographyHeading4} {...props} />

export const Heading1 = (props: string) => <h2 className={TypographyHeading1} {...props} />
export const Heading2 = (props: string) => <h3 className={TypographyHeading2} {...props} />
export const Heading3 = (props: string) => <h4 className={TypographyHeading3} {...props} />
export const Heading4 = (props: string) => <h5 className={TypographyHeading4} {...props} />
