/**
 * @description Used to draw dialcharts. Can be called directly or via MDX Rendering
 * @param {string} progress 0-100% - Number only
 * @param {string} dimension Total size of the circle
 * @param {string} radius Radius of circle
 * @param {string} strokeWidth Thinkness of scroke
 * @param {string} color Color to use.
 * @param {string} style Tailwind className (design), eks inline-block
 */
export type RadialChartProps = {
  progress: number
  dimension?: number
  radius?: number
  strokeWidth?: number
  color?: string
  style?: string
}

/**
 * Used to draw Radial Charts (0-100 circle pie chart) Idea from:
 * https://medium.com/@ekwonyenoob/building-a-simple-radial-chart-component-with-react-js-e3a3776146bd
 * @param props {RadialChartProps}
 */
export const RadialChart = (props: RadialChartProps) => {
  const setStrokeLength = 10

  // Default values if none are passed to us a props
  const progress = props.progress || 0 // Procent from 0-100 without % sign
  const dimension = props.dimension || 180
  const radius = props.radius || 80
  const strokeWidth = props.strokeWidth || 180
  const color = props.color || "red"
  const style = props.style || "inline-block"

  // Some nice math
  const circleRadius = Math.min(radius, 85)
  const circumference = 2 * 3.14 * circleRadius
  const strokeLength = setStrokeLength ? (circumference / 100) * progress : 0

  return (
    <div className={style}>
      <div className="flex items-center justify-center">
        <div className="transition-all ease-in relative text-base mx-auto">
          <svg viewBox="0 0 180 180" width={dimension} height={dimension}>
            <circle
              className="opacity-30"
              stroke={color}
              strokeWidth={strokeWidth}
              fill="none"
              cx="90"
              cy="90"
              r={circleRadius}
            />
            <circle
              className="origin-center delay-300 rotate-90 ease-in duration-500"
              stroke={color}
              strokeWidth={strokeWidth}
              strokeDasharray={`${strokeLength},${circumference}`}
              strokeLinecap="round"
              fill="none"
              cx="90"
              cy="90"
              r={circleRadius}
            />
          </svg>
        </div>
        <div className="absolute align-middle content-center">
          <span className="text-header32">{progress} %</span>
        </div>
      </div>
      <div className="relative transition-all ease-in align-middle justify-center flex"></div>
    </div>
  )
}

export type MdxRadialChartProps = {
  children: [] // as given to us by the Mdx
}

/**
 * THis is used to draw Radial Charts in MDX text as given to us by Contenful
 * @example Add the following text to contentful content.
 * <RadialChart>
 *   <Progress>0.5</Progress>
 *   <Dimension>180</Dimension>
 *   <Radius>80</Radius>
 *   <StrokeWidth>10</StrokeWidth>
 *   <Color>Yellow</Color>
 *   <Class>inline-block</Class>
 * </RadialChart>
 * @param props Props from MDX
 */
const MdxRadialChart = (props) => {
  // Default values if none are passed to us a props
  let progress = 5 // Procent from 0-100 without % sign
  let dimension = 180
  let radius = 80
  let strokeWidth = 10
  let color = "red"
  let style = "inline-block"

  // Search of our elemts if RadialChart was used in an MDX Render:
  props.children.forEach((child, i) => {
    switch (child.props.mdxType.toLowerCase()) {
      case "progress":
        progress = parseFloat(child.props.children)
        break
      case "dimension":
        dimension = parseFloat(child.props.children)
        break
      case "radius":
        radius = parseFloat(child.props.children)
        break
      case "strokewidth":
        strokeWidth = parseFloat(child.props.children)
        break
      case "color":
        color = child.props.children
        break
      case "style":
        style = child.props.children
        break
    }
  })

  const RadialProps: RadialChartProps = {
    progress: progress,
    dimension: dimension,
    radius: radius,
    strokeWidth: strokeWidth,
    color: color,
    style: style,
  }

  return <RadialChart {...RadialProps} />
}

export default MdxRadialChart
