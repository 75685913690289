import { useState } from "react"

/**
 * @name ToogleProps
 * @description Used to set Props needed to create a Toogle
 * @author Frode Stenstrøm
 * @param id Used by span and aria tags
 * @param default Set to true to keep toggle on by default
 * @param allowToggle Set to false to deny change of current value.
 * @param title Text to put to the right of the toggle
 * @param props A string array of props to be sent to your function as defined by @action
 * @param action a simple function that is called when the toogle is turned on or off.
 * @todo enforce rule "sett by allow" toggle
 * See https://github.com/typescript-cheatsheets/react#basic-prop-types-examples for type examples
 */
export type ToogleProps = {
  id: string
  default?: boolean
  allowToggle?: boolean
  title?: string
  props: string[]
  action: (props: string[], action: "On" | "Off") => void
}
// Remember to use type functionChildren: (name: string) => React.ReactNode; when the function also returns react node elements

/**
 * Used to create a Toogle button
 * @param props ToogleProps
 */
const Toggle = (props: ToogleProps) => {
  if (props.default === undefined) {
    props.default = false
  }

  const [isOn, setIsOn] = useState(props.default)

  return (
    <>
      <div className="flex items-stretch flex-wrap">
        <div>
          <button
            type="button"
            className={`${isOn ? "bg-brokoli-500" : "bg-sort-600"} 
          relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brokoli-500`}
            aria-pressed={isOn}
            aria-labelledby={props.id}
            onClick={() => {
              isOn ? props.action(props.props, "Off") : props.action(props.props, "On")
              setIsOn(!isOn)
            }}
          >
            <span className="sr-only">Bruk innstilling</span>
            <span
              aria-hidden="true"
              className={`${
                isOn ? "translate-x-5" : "translate-x-0"
              } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
            >
              {isOn ? (
                <>
                  {/* Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" */}
                  <span
                    className="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                    aria-hidden="true"
                  >
                    <svg className="bg-white h-3 w-3 text-sort-600" fill="none" viewBox="0 0 12 12">
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  {/* Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" */}
                  <span
                    className="opacity-100 ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                    aria-hidden="true"
                  >
                    <svg className="bg-white h-3 w-3 text-brokoli-500" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </>
              ) : (
                <>
                  {/* Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" */}
                  <span
                    className="opacity-100 ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                    aria-hidden="true"
                  >
                    <svg className="bg-white h-3 w-3 text-sort-600" fill="none" viewBox="0 0 12 12">
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  {/* Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" */}
                  <span
                    className="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                    aria-hidden="true"
                  >
                    <svg className="bg-white h-3 w-3 text-brokoli-500" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </>
              )}
            </span>
          </button>
        </div>
        <div className="self-stretch">
          <span className="ml-1 sm:ml-1 md:ml-2" id={props.id}>
            <span className="text-sm font-medium text-gray-900">{props.title}</span>
          </span>
        </div>
      </div>
    </>
  )
}
export default Toggle
