export const toDisplayDate = (date: string): string => {
  if (date !== undefined && date !== null) {
    const day_ = Number(date.substring(0, 2))
    const month_ = Number(date.substring(3, 5)) - 1 // Month is null based
    const year_ = Number(date.substring(6, 10))
    const newDate = new Date(year_, month_, day_)
    const options = { year: "numeric", month: "long", day: "numeric" }
    const prettyDate = new Intl.DateTimeFormat("no-NO", options).format(newDate)

    return prettyDate
  } else return ""
}

// Inputformat på datostreng:2021-12-27
export const toPrettyDate = (date: string): string => {
  if (date !== undefined && date !== null) {
    const year_ = Number(date.substring(0, 4))
    const month_ = Number(date.substring(5, 7)) - 1 // Month is null based
    const day_ = Number(date.substring(8, 10))
    const newDate = new Date(year_, month_, day_)
    const options = { year: "numeric", month: "numeric", day: "numeric" }
    const prettyDate = new Intl.DateTimeFormat("no-NO", options).format(newDate)

    return prettyDate
  } else return ""
}

/**
 * @description: parses dateTime to the correct date format
 * @param props date in format dd.MM.yyyy HH:mm:ss
 * @returns date in format dd.MMM.yyyy
 */
export const parseDate = (date: string) => {
  if (!(date !== undefined && date !== null)) return ""
  // remove time and split date into array of day, month and year
  const dateComponent = date.split(" ")[0].split(".")
  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
  const newDate = dateComponent[0] + "." + months[parseInt(dateComponent[1]) - 1] + "." + dateComponent[2]
  return newDate
}

/**
 * @description: parses dateTime to the correct date format
 * @param props date in format dd.MM.yyyy HH:mm:ss
 * @returns date in format dd.MM.yyyy
 */
export const parseDate2 = (date: string) => {
  if (!(date !== undefined && date !== null)) return ""
  // remove time and split date into array of day, month and year
  const dateComponent = date.split(" ")[0].split(".")
  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
  const days = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ]
  const newDate =
    days[parseInt(dateComponent[0]) - 1] + "." + months[parseInt(dateComponent[1]) - 1] + "." + dateComponent[2]
  return newDate
}

/**
 * @description converts from dd.MM.yyyy hh.mm.ss to yyyy.mm.ddThh:mm:ss (Example 2011-10-10T14:48:00)
 */
export const DateConvertDmyToMdy = (date) => {
  if (date === null || date === undefined) return ""
  const dateArray = date.replace(" ", ".").split(".")

  return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0] + "T" + dateArray[3] + ".000+02:00"
}

export const SortByDate = (items) => {
  // Sort all related articles from new to old. Let GH override with createdAtModified
  items.sort((a, b) => {
    let dateA = new Date(DateConvertDmyToMdy(a.createdAt))
    if (a.updatedAt !== undefined && a.updatedAt !== null) dateA = new Date(DateConvertDmyToMdy(a.updatedAt))
    if (a.createdAtModified !== undefined && a.createdAtModified !== null)
      dateA = new Date(DateConvertDmyToMdy(a.createdAtModified))

    let dateB = new Date(DateConvertDmyToMdy(b.createdAt))
    if (b.updatedAt !== undefined && b.updatedAt !== null) dateB = new Date(DateConvertDmyToMdy(b.updatedAt))
    if (b.createdAtModified !== undefined && b.createdAtModified !== null)
      dateB = new Date(DateConvertDmyToMdy(b.createdAtModified))

    if (dateA > dateB) {
      return -1
    }
    if (dateA < dateB) {
      return 1
    }
    // dates must be equal
    return 0
  })

  return items
}
