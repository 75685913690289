import { AuthError, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { loginRequest, msalConfig } from "./authConfig"
import { MDXProvider } from "@mdx-js/react"
import MdxComponent from "../Styling/MdxComponent"
import { CustomNavigationClient } from "./NavigationClient"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { navigate } from "@reach/router"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const msalInstance = new PublicClientApplication(msalConfig)

const Provider = ({ element }) => {
  // The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigationClient = new CustomNavigationClient()
  msalInstance.setNavigationClient(navigationClient)

  const callbackId = msalInstance.addEventCallback((message: EventMessage) => {
    console.log(message.eventType)
    if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
      if (message.error instanceof AuthError) {
        console.log("EventType.ACQUIRE_TOKEN_FAILURE")
        console.log(message.error.message)
        navigate("/minside/")
        // if (message.error.message.includes("AADB2C90091")) navigate("/minside/")
      }
    }

    if (message.eventType === EventType.LOGIN_FAILURE) {
      if (message.error instanceof AuthError) {
        console.log("EventType.LOGIN_FAILURE")
        console.log(message.error.message)
        const request = {
          ...loginRequest,
        }
        msalInstance.acquireTokenRedirect(request)
      }
    }

    if (message.eventType === EventType.HANDLE_REDIRECT_END) {
      if (message.error instanceof AuthError) {
        console.log("EventType.HANDLE_REDIRECT_END")
        console.log(message.error.message)
        const request = {
          ...loginRequest,
        }
        msalInstance.acquireTokenRedirect(request)
        // if (message.error.message.includes("AADB2C90091")) navigate("/minside/")
      }
    }
  })

  return (
    <>
      <MsalProvider instance={msalInstance}>
        <QueryClientProvider client={queryClient}>
          <MDXProvider components={MdxComponent}>{element}</MDXProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </MsalProvider>
    </>
  )
}

export default Provider
