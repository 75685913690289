/**
 * This is the MDX component for LISTS.
 */
const UnorderedHtmlListWrapper = ({ children }) => {
  return (
    <>
      {/* Wrappper of entire list */}
      <ul className="ml-6 sm:ml-12 mr-4 mb-6 font-sans font-normal text-body18  list-outside list-disc">{children}</ul>
    </>
  )
}

const OrderedHtmlListWrapper = ({ children }) => {
  return (
    <>
      {/* Wrappper of entire list */}
      <ol className="font-sans font-normal text-body18  list-inside list-decimal">{children}</ol>
    </>
  )
}

const ListItem = ({ children }) => {
  return (
    <>
      {/* Wrapper of header. */}
      <li>{children}</li>
    </>
  )
}

export { UnorderedHtmlListWrapper, OrderedHtmlListWrapper, ListItem }
