import { Link } from "gatsby-plugin-react-i18next"
import { Image } from "cloudinary-react"
import { StylingFooterItem } from "../Styling/Style"

const LinkSubItemFooterDesktop = ({ url, title }) => (
  <li>
    <Link to={url} className={StylingFooterItem}>
      {title}&nbsp;
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </Link>
  </li>
)

const ExternalLinkSubItemFooterDesktop = ({ url, title }) => (
  <li>
    <a href={url} target="_blank" rel="noreferrer" className={StylingFooterItem}>
      {title} &nbsp;
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </a>
  </li>
)

/**
 * @name: Footer
 *
 */
export default function Footer() {
  return (
    <>
      <footer className="text-white" aria-labelledby="footerHeading">
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>

        {/* Start footer section */}
        <div className="relative bg-tomat-500 max-w-full  grid gap-4 lg:gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 content-center  pb-14 md:pb-48  sm:px-12 md:pl-12 md:pr-1 lg:pl-20  xl:px-36 place-content-center">
          {/* 4 grid coloum */}

          <div className="sm:col-span-2 md:col-span-3 lg:col-span-4 py-6 xl:py-14 ">
            {/* Logo */}
            <Link to="/">
              <Image
                cloudName={process.env.CLOUDINARY_CLOUD_NAME}
                publicId="Logo/GH-breddelogo-hvit_ikukl3.svg"
                alt="Gartnerhallen Logo"
                loading="lazy"
                className="hidden lg:block h-36 w-auto"
                secure
              />
              <Image
                cloudName={process.env.CLOUDINARY_CLOUD_NAME}
                publicId="Logo/GH-breddelogo-hvit_ikukl3.svg"
                alt="Gartnerhallen Logo"
                loading="lazy"
                className="block lg:hidden h-28 w-auto"
                secure
              />
            </Link>
          </div>

          <div>
            <h3 className="pb-2 font-bold">Postadresse:</h3>
            <p>Gartnerhallen SA</p>
            <p>Nedre Kalbakkvei 40</p>
            <p>Postboks 111 - Alnabru</p>
            <p>0614 OSLO</p>

            <h3 className="pb-2 pt-7 font-bold">Besøksadresse:</h3>
            <p>Nedre Kalbakkvei 40</p>
            <p>1081 Oslo</p>
            <div className="pt-7 pb-2">
              <ul>
                <ExternalLinkSubItemFooterDesktop
                  url="https://www.google.com/maps/place/Gartnerhallen/@59.9396811,10.8660235,17z/data=!3m1!4b1!4m5!3m4!1s0x46416fee588552fd:0x8452237f794626d1!8m2!3d59.9396811!4d10.8682122"
                  title="Se på kart"
                />
                {/* <a
                href="https://www.google.com/maps/place/Gartnerhallen/@59.9396811,10.8660235,17z/data=!3m1!4b1!4m5!3m4!1s0x46416fee588552fd:0x8452237f794626d1!8m2!3d59.9396811!4d10.8682122"
                className=" font-bold"
              >
                Se på kart
              </a> */}
              </ul>
            </div>
          </div>

          <div>
            <h3 className="pb-2 font-bold">Telefon:</h3>
            <p>23 24 93 00</p>

            <h3 className="pb-2 pt-7 font-bold">E-post</h3>
            <p>post@gartnerhallen.no</p>

            <h3 className="pb-2 pt-7 font-bold">Org. nr.</h3>
            <p>NO 945 958 405 MVA</p>
          </div>

          <div className=" pt-12 md:pt-0 ">
            <ul>
              <LinkSubItemFooterDesktop url="/om-gartnerhallen" title="Om Gartnerhallen" />
              <LinkSubItemFooterDesktop url="/produktgrupper" title="Våre produkter" />
              <LinkSubItemFooterDesktop url="/for-produsenter" title="For produsenter" />
              <LinkSubItemFooterDesktop url="/tema" title="Tema" />
              <LinkSubItemFooterDesktop url="/aktuelt" title="Aktuelt" />
            </ul>
          </div>

          {/* Last column, social links */}
          <div className=" pt-12 md:pt-0 ">
            <ul>
              <ExternalLinkSubItemFooterDesktop
                url="https://www.linkedin.com/company/gartnerhallen-sa/"
                title="LinkedIN"
              />
              <ExternalLinkSubItemFooterDesktop url="https://facebook.com/gartnerhallen" title="Facebook" />
              <ExternalLinkSubItemFooterDesktop url="https://www.instagram.com/gartnerhallen_sa/" title="Instagram" />
            </ul>
          </div>
        </div>

        {/* End 4 column */}
        {/* End section 1 of footer */}
        {/* Start of bottum */}
        <div className=" bg-brun-500 mx-auto px-10 max-w-full flex flex-wrap content-center py-24 ">
          &copy; 2021 Gartnerhallen.no - Vi bruker&nbsp;
          <Link to="/personvern" className="hover:underline">
            informasjonskapsler
          </Link>
          &nbsp;- Design: <a href="https://metrobranding.no">&nbsp;Metro Branding</a>&nbsp;- Utvikling:&nbsp;
          <a href="https://prosesspilotene.no"> ProsessPilotene as</a>.
        </div>
      </footer>
    </>
  )
}
