import { Image, Transformation, Placeholder } from "cloudinary-react"
import { ReactChildren } from "react"

/**
 * Image handler for å vise alle bilder på hele nettsiden  - Ikke i bruke enda.
 * Venter på https://github.com/cloudinary-devs/gatsby-transformer-cloudinary/issues/90
 * Da kan vi bruke denne plugin sammen med nyeste gatsby-plugin-image
 * @returns
 */

type CloudinaryImageType = {
  children?: ReactChildren
  publicId?: string
  alt?: string
}

const CloudinaryImage = (props: CloudinaryImageType) => {
  const publicId = props.publicId || props.children || ""
  return (
    <>
      <Image
        dpr="auto"
        responsive
        width="auto"
        crop="scale"
        responsiveUseBreakpoints="true"
        cloudName={process.env.CLOUDINARY_CLOUD_NAME}
        publicId={publicId}
        alt={props.alt || ""}
        loading="lazy"
        className="object-cover object-center"
        secure
      >
        <Transformation quality="auto" fetchFormat="webp" />
      </Image>
    </>
  )
}

export default CloudinaryImage
