import { Link } from "gatsby-plugin-react-i18next"

// TODO Create link component.
// Devide this file into multiple components

export type LinkProps = {
  url: string
  title: string
}

// Links with icons - Used by MyPage Sidebar

export const LinkDefault = (props: LinkProps) => (
  <Link
    to={props.url}
    className="border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
  >
    {props.title}
  </Link>
)

export const LinkHome = (props: LinkProps) => (
  <Link
    to={props.url}
    className="border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
  >
    <svg
      className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
      />
    </svg>
    {props.title}
  </Link>
)

export const LinkProductTool = (props: LinkProps) => (
  <Link
    to={props.url}
    className="border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
  >
    <svg
      className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
      />
    </svg>
    {props.title}
  </Link>
)

export const LinkReports = (props: LinkProps) => (
  <Link
    to={props.url}
    className="border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
  >
    <svg
      className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
      />
    </svg>
    {props.title}
  </Link>
)

export const LinkMessages = (props: LinkProps) => (
  <Link
    to={props.url}
    className="border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
  >
    <svg
      className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
      />
    </svg>
    {props.title}
  </Link>
)

export const LinkMyRegion = (props: LinkProps) => (
  <Link
    to={props.url}
    className="border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
  >
    <svg
      className="mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
      />
    </svg>
    {props.title}
  </Link>
)

export const LinkSettings = (props: LinkProps) => (
  <Link
    to={props.url}
    className="border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
  >
    <svg
      className="text-purple-500 mr-3 h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
      />
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
    {props.title}
  </Link>
)

// Form fields
export const inputSearch =
  "shadow-sm focus:ring-tomat-500 focus:border-tomat-500 block w-full sm:text-sm border-gray-300 rounded-full"
export const inputCenterOrAlone =
  "focus:ring-tomat-500 focus:border-tomat-500 w-full pl-5 text-body18 font-medium text-black bg-white rounded-3xl sm:rounded-none"
export const leftInputNotAlone =
  "focus:ring-tomat-500 focus:border-tomat-500 w-full pl-5 text-body18 font-medium text-black bg-white rounded-3xl sm:rounded-r-none "
export const rightCtaButton =
  "ring-tomat-500 ring-1 focus:ring-tomat-500 focus:border-tomat-500 focus:bg-tomat-500 focus:text-white w-full pl-5 text-body18 font-bold text-black rounded-3xl md:rounded-l-none bg-white px-6 py-2 hover:bg-tomat-500 hover:text-white"
// Typography styles

export const TypographyLink = "text-black underline hover:text-tomat-500 hover:underline" // a href

export const StylingMenuHeading =
  "font-silksans text-menyheader28 2xl:text-menyheader40 text-squash-500 font-medium text-left hover:underline hover:text-tomat-500 "
export const StylingMenuItem = "hidden md:block lg:block text-body18 text-sort-200 underline hover:text-tomat-500 py-2"

export const ProductGroupItems =
  "font-silksans text-header40 xl:text-header48 text-squash-500 rounded-md font-medium text-left hover:underline hover:text-tomat-500"

export const StylingFooterItem = "block py-1 text-body18 text-white hover:underline hover:text-squash-500"

export const TypographyAccordionMenuItem = "text-black text-faktaboks21"

export const TypographyRelatedCardHeading =
  "font-silksans text-squash-500 hover:text-tomat-500 text-header40 py-8 text-center"
export const TypographyRelatedCardTitle = "text-squash hover:text-tomat-500 text-header24"
export const TypographyRelatedCardDate = "text-button15 font-medium text-sort-200"

// Badge styles

export const MinSideButton =
  "bg-transparent md:hover:bg-tomat-500 hover:text-gray-600 md:py-2 font-normal md:border md:border-tomat-500  md:rounded-full"

export const BadgeStyleTransparentRed =
  "md:text-tomat-500 hover:text-tomat-500 md:hover:text-white px-3 md:px-6 md:py-4 rounded-md text-xs md:text-base text-sort-200"

export const ButtonStyle =
  "inline-flex items-center px-3 sm:px-6 py-3 sm:py-4 text-body14 sm:text-button15 font-normal text-black bg-white rounded-full border border-tomat-500 hover:text-white hover:bg-tomat-500 click:bg-red-900 click:text-white focus:bg-white focus:text-black focus:rounded-full disabled:text-white disabled:bg-sort-100"

export const TypographyEmployeeCardHeading = "font-axiforma font-normal font-semibold text-employee18"
export const TypographyEmployeeCardName = "font-axiforma font-normal font-semibold text-employee21"
