import { useState, useEffect } from "react"
import { TypographyParagraph } from "./Body"

/**
 * @description This will create an Factbox menu.
 * The component expects to get the data needed to draw the component via props
 * The data in contentful is Factbox and Factbox Item.
 * TODO: Add styling from Metro Branding
 */

// Factbox elements

/**
 * Factbox wrapper for the entire Factbox
 * @param props Factbox children
 */
const Factbox = (props) => {
  const [children, setChildren] = useState([])

  useEffect(() => {
    setChildren(() => (props.children.length ? props.children : [props.children]))
  }, [props.children])

  let keyId = 0
  const newItems = props.children.map((item) => ({
    ...item,
    key: keyId++,
  }))

  return (
    <div className="min-w-full mx-auto grid grid-flow-rows sm:grid-cols-2  sm:gap-4  gap-8">
      {newItems.map((elt) => (
        <elt.type {...elt.props} key={elt.key} open={true}></elt.type>
      ))}
    </div>
  )
}
export default Factbox

/**
 * Wrapper of factbox items.
 * @param props
 */
export const FactBoxItem = (props) => {
  const body = props.children

  return (
    <div>
      <span className={TypographyParagraph + " bg-knapplysgronn-500 py-1 "}>{body}</span>{" "}
    </div>
  )
}
