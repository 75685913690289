import { useEffect, useState, useContext, createContext } from "react"
import { Transition } from "@headlessui/react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { Link } from "gatsby-plugin-react-i18next"
import { StylingMenuItem, StylingMenuHeading, MinSideButton, BadgeStyleTransparentRed } from "../Styling/Style"
import { Image } from "cloudinary-react"
import { useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react"
import { AllMainPaths } from "../Common/MainPaths"
import { SortByDate } from "../Common/DateConverter"
import { loginRequest } from "../Security/authConfig"
import { fetchApi } from "../Common/WebApi-utils"
import { useQuery } from "react-query"

/**
 * This creates a main menu based on contentful data.
 * It is as dynamics as possible given time available.
 */
const ContentFulMenus = (ContentfulMenyProps) => {
  const isMainMenuOpen = ContentfulMenyProps.isMainMenuOpen
  const setIsMainMenuOpen = ContentfulMenyProps.setIsMainMenuOpen
  const isAuthenticated = useIsAuthenticated()

  const allMainPaths = AllMainPaths()

  // Get all menu data from Contentful
  const data = useStaticQuery(graphql`
    {
      allContentfulNavigation(
        filter: { id: { eq: "f97bbd4f-f468-50e2-8f83-391ece798e63" }, node_locale: { eq: "nb-NO" } }
      ) {
        nodes {
          name
          id
          slug
          navigationItem {
            ... on ContentfulNavigationItem {
              id
              slug
              title
              internal {
                type
              }
            }
            ... on ContentfulNavigation {
              id
              name
              navigationItem {
                ... on ContentfulNavigationItem {
                  id
                  slug
                  title
                  internal {
                    type
                  }
                }
              }
              slug
              internal {
                type
              }
            }
          }
        }
      }
      allContentfulProductGroup(filter: { node_locale: { eq: "nb-NO" } }) {
        nodes {
          id
          title
          slug
        }
      }
      allContentfulTopics(filter: { node_locale: { eq: "nb-NO" } }) {
        nodes {
          id
          slug
          title
        }
      }
      allContentfulCategories(filter: { node_locale: { eq: "nb-NO" } }) {
        nodes {
          id
          slug
          category
        }
      }
      allContentfulFarmer(limit: 3, sort: { fields: updatedAt, order: ASC }, filter: { node_locale: { eq: "nb-NO" } }) {
        nodes {
          id
          slug
          title
        }
      }
      allContentfulArticle(filter: { node_locale: { eq: "nb-NO" }, public: { eq: true }, articleType: { eq: false } }) {
        nodes {
          createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
          createdAtModified(formatString: "DD.MM.YYYY hh:mm:ss")
          updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
          slug
          title
          id
          public
        }
      }
    }
  `)
  // Sortere produktgruppene alfabetisk
  data.allContentfulProductGroup.nodes.sort((a, b) => {
    const titleA = a.title.toUpperCase() // ignore upper and lowercase
    const titleB = b.title.toUpperCase() // ignore upper and lowercase
    if (titleA < titleB) {
      return -1
    }
    if (titleA > titleB) {
      return 1
    }
    // names must be equal
    return 0
  })

  // Bygg opp liste over produktgrupper for bruk av denne komponenten, alfabetisk.
  const dataProductGroups = data.allContentfulProductGroup.nodes.map((item, counter) => ({
    index: counter,
    slug: item.slug,
    title: item.title || "Tittel mangler i contentful",
  }))

  // Find all products to add as items to the product menu. Filter as need
  const productGroupItems = dataProductGroups.map((item) => (
    <li key={item.id}>
      <Link
        to={"/nb/" + allMainPaths.productgroup + "/" + item.slug}
        className={StylingMenuItem}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        {item.title}
      </Link>
    </li>
  ))

  // Create the product menu
  const productMenu = (
    <div>
      <Link
        to={"/" + allMainPaths.productgroup}
        className={StylingMenuHeading}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        Bak produktene
      </Link>

      <ul className="hover:triangel-hvit md:mt-6  md:mb-8">{productGroupItems}</ul>
    </div>
  )

  // Find all topics to add as items to the topic menu. Filter as need
  const topicItems = data.allContentfulTopics.nodes.map((item) => (
    <li key={item.id}>
      <Link
        to={"/nb/" + allMainPaths.topics + "/" + item.slug}
        className={StylingMenuItem}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        {item.title}
      </Link>
    </li>
  ))

  // Create the TOPICS menu
  const topicMenu = (
    <div>
      <Link
        to={"/" + allMainPaths.topics}
        className={StylingMenuHeading}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        Tema
      </Link>
      <ul className="md:mt-6  md:mb-8">{topicItems}</ul>
    </div>
  )

  // Find all categories to add as items to the category menu. Filter as need
  // const categoryItems = data.allContentfulCategories.nodes.map((item) => (
  //   <li key={item.id}>
  //     <Link
  //       to={"/nb/kategori/" + item.slug}
  //       className={StylingMenuItem}
  //       role="menuitem"
  //       onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
  //     >
  //       {item.category}
  //     </Link>
  //   </li>
  // ))

  // Create the Category menu
  // const categoryMenu = (
  //   <div>
  //     <Link
  //       to="/kategori"
  //       className={StylingMenuHeading}
  //       role="menuitem"
  //       onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
  //     >
  //       Kategorier
  //     </Link>
  //     <ul>{categoryItems}</ul>
  //   </div>
  // )

  // Find all Farmers to add as items to the farmers menu. Filter as need
  const farmerItems = data.allContentfulFarmer.nodes.map((item) => (
    <li key={item.id}>
      <Link
        to={"/nb/" + allMainPaths.farmer + "/" + item.slug}
        className={StylingMenuItem}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        {item.title}
      </Link>
    </li>
  ))

  // Create the Farmer menu
  const farmerMenu = (
    <div>
      <Link
        to={"/" + allMainPaths.farmer}
        className={StylingMenuHeading}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        Møt produsentene
      </Link>
      <ul className="md:mt-6  md:mb-8">{farmerItems}</ul>
    </div>
  )

  // Find all Aktuelt to add as items to the aktuelt menu. Filter as need
  const sortedArticles = SortByDate(data.allContentfulArticle.nodes)

  const aktueltItems = sortedArticles.slice(0, 3).map((item) => (
    <li key={item.id}>
      <Link
        to={"/nb/" + allMainPaths.article + "/" + item.slug}
        className={StylingMenuItem}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        {item.title}
      </Link>
    </li>
  ))

  // Create the aktuelt menu
  const aktueltMenu = (
    <div>
      <Link
        to={"/" + allMainPaths.article}
        className={StylingMenuHeading}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        Aktuelt
      </Link>
      <ul className="md:mt-6  md:mb-8">{aktueltItems}</ul>
    </div>
  )

  // Find our 3 main menus from Contentful
  const {
    allContentfulNavigation: { nodes: navigationItem },
  } = data

  let menuKontaktOssItems
  let menuOmGartnerhallenItems
  let menuForProdusenterItems

  navigationItem[0].navigationItem.forEach((child) => {
    switch (child.id) {
      case "00f356d6-c266-5eac-90c3-bc34d92f82d2": // Kontakt oss
        menuKontaktOssItems = { ...child }
        break
      case "f58b8eab-fb7a-52c4-8410-d8b604bb80ec": // Om Gartnerhallen
        menuOmGartnerhallenItems = { ...child }
        break
      case "8829f26d-e805-5538-ab1c-a878b19405f7": // For Produsenter
        menuForProdusenterItems = { ...child }
        break
    }
  })

  const omGartnerhallenItems = menuOmGartnerhallenItems.navigationItem.map((item) => (
    <li key={item.id}>
      <Link
        to={"/om-gartnerhallen/#" + item.slug}
        className={StylingMenuItem}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        {item.title}
      </Link>
    </li>
  ))

  // Create the Om Gartnerhallen menu
  const omGartnerhallenMenu = (
    <div>
      <Link
        to="/om-gartnerhallen"
        className={StylingMenuHeading}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        Om Gartnerhallen
      </Link>
      <ul className="md:mt-6  md:mb-8">{omGartnerhallenItems}</ul>
    </div>
  )

  // Find all ForProdusenter  to add as items to the aktuelt menu. Filter as need
  const ForProdusenterItems = menuForProdusenterItems.navigationItem.map((item) => (
    <li key={item.id}>
      <Link
        to={"/for-produsenter/" + item.slug}
        className={StylingMenuItem}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        {item.title}
      </Link>
    </li>
  ))
  // ForProdusenterItems.push(
  //   <li key="minside">
  //     <a
  //       href={"https://medlem.gartnerhallen.no/menystyring/meny/logginn.asp"}
  //       className={StylingMenuItem}
  //       role="menuitem"
  //       onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
  //     >
  //       {"Logg inn"}
  //     </a>
  //   </li>
  // )
  ForProdusenterItems.push(
    <li key="minside">
      <Link
        to="/minside"
        className="md:hidden font-silksans text-menyheader28 2xl:text-menyheader40 text-squash-500 font-medium text-left hover:underline hover:text-tomat-500"
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        Logg inn
      </Link>
    </li>
  )
  // Create the ForProdusenter menu
  const ForProdusenter = (
    <div>
      <Link
        to={"/for-produsenter"}
        className={StylingMenuHeading}
        role="menuitem"
        onClick={() => {
          setIsMainMenuOpen(!isMainMenuOpen)
        }}
      >
        For produsenter
      </Link>
      <ul className="md:mt-6  md:mb-8">{ForProdusenterItems}</ul>
    </div>
  )

  // Find all kontaktOssItems  to add as items to the aktuelt menu. Filter as need
  const kontaktOssItems = menuKontaktOssItems.navigationItem.map((item) => (
    <li key={item.id}>
      <Link
        to={"/kontakt/#" + item.slug}
        className={StylingMenuItem}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        {item.title}
      </Link>
    </li>
  ))

  // Create the ForProdusenter menu
  const kontaktOss = (
    <div>
      <Link
        to="/kontakt"
        className={StylingMenuHeading}
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        Kontakt oss
      </Link>
      <ul className="md:mt-6 md:mb-8">{kontaktOssItems}</ul>
    </div>
  )

  const loggInn = (
    <div>
      <Link
        to="https://medlem.gartnerhallen.no/menystyring/meny/logginn.asp"
        className="sm:hidden font-silksans text-menyheader28 2xl:text-menyheader40 text-squash-500 font-medium text-left hover:underline hover:text-tomat-500"
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        Min side
      </Link>

      {/* <Link
        to="/minside"
        className="font-silksans text-menyheader28 2xl:text-menyheader40 text-squash-500 font-medium text-left hover:underline hover:text-tomat-500"
        role="menuitem"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
      >
        Min side
      </Link> */}

      <ul className="md:mt-6 md:mb-8">
        <li key="faktura">
          {isAuthenticated ? (
            <Link
              to={"/minside/okonomi/fakturaer"}
              className={StylingMenuItem}
              role="menuitem"
              onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
            >
              {"Mine fakturaer"}
            </Link>
          ) : (
            <Link
              to={"/minside/"}
              className={"hidden"}
              role="menuitem"
              onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
            ></Link>
          )}
        </li>
        <li key="produksjonsplan">
          {isAuthenticated ? (
            <Link
              to={"/minside/produksjonsplan"}
              className={StylingMenuItem}
              role="menuitem"
              onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
            >
              {"Min produksjonsplan"}
            </Link>
          ) : (
            <Link
              to={"/minside/"}
              className={"hidden"}
              role="menuitem"
              onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
            ></Link>
          )}
        </li>
        <li key="logginn">
          {isAuthenticated ? (
            <Link
              to={"/minside/innstillinger/Logout"}
              className={StylingMenuItem}
              role="menuitem"
              onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
            >
              {"Logg ut"}
            </Link>
          ) : (
            <Link
              to={"/minside/"}
              className={StylingMenuItem}
              role="menuitem"
              onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
            >
              {"Logg inn"}
            </Link>
          )}
        </li>
      </ul>
    </div>
  )

  return (
    <>
      {productMenu}
      {topicMenu}
      {farmerMenu}
      {aktueltMenu}
      {omGartnerhallenMenu}
      {ForProdusenter}
      {kontaktOss}
      {loggInn}
    </>
  )
}

function MegaMenuLogo({ location }) {
  const [showGhText, setShowGhText] = useState(false)
  const currentUrl = location.pathname

  if (showGhText && (currentUrl.includes("minside") || currentUrl.includes("veiledning"))) setShowGhText(!showGhText)

  if (!showGhText && !(currentUrl.includes("minside") || currentUrl.includes("veiledning"))) setShowGhText(!showGhText)

  return (
    // TODO Make sure to close main menu if clicked.
    <Link to="/">
      <div className={showGhText ? "hidden lg:block xl:block" : "hidden md:block lg:block xl:block"}>
        <Image
          cloudName={process.env.CLOUDINARY_CLOUD_NAME}
          publicId="Logo/GH-breddelogo_RGB_bze48r.svg"
          alt="Gartnerhallen Logo"
          // loading="lazy"
          className="block h-16 sm:h-20 md:h-28 lg:h-32 w-auto z-50"
          secure
          // dpr="auto"
          // responsive
          // width="auto"
          // responsiveUseBreakpoints="true"
        />
      </div>
      <div className={showGhText ? "absolute left-5 top-5 lg:hidden" : "absolute left-5 top-5 md:hidden"}>
        <Image
          cloudName={process.env.CLOUDINARY_CLOUD_NAME}
          publicId="Logo/GH-logosymbol_RGB_egttsq.png"
          alt="Gartnerhallen Logo"
          // loading="lazy"
          className="block h-10 sm:h-16 w-auto"
          secure
          // dpr="auto"
          // responsive
          // width="auto"
          // responsiveUseBreakpoints="true"
        />
      </div>
    </Link>
  )
}

/* Search feature */
const Search = () => (
  <>
    <label htmlFor="search" className="sr-only">
      Søk
    </label>
    {/* Heroicon name: search */}
    <Link to="/sok">
      <div className="hidden md:block">
        <span className="px-3 py-2">Søk</span>
        <svg
          className="h-6 w-6 text-sort-200 inline-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="md:hidden">
        <svg
          className="h-6 w-6 text-sort-200 inline-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </Link>
  </>
)

function MegaMenu({ location }) {
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false)

  const ContentfulMenyProps = {
    isMainMenuOpen: isMainMenuOpen,
    setIsMainMenuOpen: setIsMainMenuOpen,
  }

  const [showMegaMenuBtn, setShowMegaMenuBtn] = useState(false)
  const [currentUrlMyPage, setCurrentUrlMyPage] = useState(false)
  // const currentUrl = location.pathname

  // if (!showMegaMenuBtn && (currentUrl.includes("minside") || currentUrl.includes("veiledning")))
  //   setShowMegaMenuBtn(!showMegaMenuBtn)

  // if (showMegaMenuBtn && !(currentUrl.includes("minside") || currentUrl.includes("veiledning")))
  //   setShowMegaMenuBtn(!showMegaMenuBtn)

  useEffect(() => {
    if (location.pathname.includes("minside") || location.pathname.includes("veiledning")) {
      setCurrentUrlMyPage(true)
    } else {
      setCurrentUrlMyPage(false)
    }
  }, [location.pathname])

  return (
    <>
      {/* Button: Open Close Mega Menu */}
      <button
        type="button"
        onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
        className={
          currentUrlMyPage
            ? "hidden md:inline-flex items-center z-50 justify-center p-1 sm:p-2 -mr-2 text-sort-200 hover:text-white hover:bg-tomat-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white rounded-md  text-header18 lg:text-lg font-medium "
            : "inline-flex items-center z-50 justify-center p-1 sm:p-2 -mr-2 text-sort-200 hover:text-white hover:bg-tomat-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white rounded-md  text-header18 lg:text-lg font-medium visible"
        }
      >
        <span className="sr-only">Åpne hovedmeny</span>
        {/* Heroicon name: menu  Menu open: "hidden", Menu closed: "block" */}
        {/* Heroicon name: x Menu open: "block", Menu closed: "hidden" */}
        <span className="px-3 py-2 z-50 hidden md:block">Meny</span>

        {isMainMenuOpen ? (
          <svg
            className="block h-6 w-6 z-50"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        ) : (
          <svg
            className="block h-6 w-6 z-50"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        )}
      </button>

      <Transition
        show={isMainMenuOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 -translate-y-1"
        // enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        {(ref) => (
          <div
            ref={ref}
            className="absolute left-0 top-0 z-40 inset-x-0 transform shadow-lg min-h-screen  bg-knapplysgronn-500"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            <div className="grid ml-8 xl:ml-24 pt-40 2xl:pt-48 pb-16 grid-cols-1 gap-6 sm:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 sm:px-4 lg:px-8 text-sort-200">
              <ContentFulMenus {...ContentfulMenyProps} />
            </div>
          </div>
        )}
      </Transition>
    </>
  )
}

export function MyPageName({ location }) {
  const { accounts, instance, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const isAuthenticated = useIsAuthenticated()

  const [isUrlMyPage, setIsUrlMyPage] = useState(false)
  const currentUrl = location.pathname
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")

  if (!isUrlMyPage && (currentUrl.includes("minside") || currentUrl.includes("support"))) setIsUrlMyPage(!isUrlMyPage)

  if (isUrlMyPage && !(currentUrl.includes("minside") || currentUrl.includes("support"))) setIsUrlMyPage(!isUrlMyPage)

  const userProfileQuery = useQuery(
    "userProfileData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Contacts", account, inProgress, instance).then((res) => res.data),
    {
      enabled: !!isAuthenticated,
      onSuccess: (data) => {
        setFirstName(data.firstName)
        setLastName(data.lastName)
      },
    }
  )

  return (
    <>
      <div className={!isUrlMyPage ? "visible" : "hidden"}>
        <button
          type="button"
          onClick={() => navigate("/minside")}
          className="inline-flex items-center z-50 justify-center p-1 sm:p-2 -mr-2 text-sort-200 hover:text-tomat-500   focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white rounded-md    "
        >
          <span className="text-xs md:flex md:text-body18 md:visible">{firstName}</span>
        </button>
      </div>
      <div className={isUrlMyPage ? "visible" : "hidden"}>
        <button
          type="button"
          onClick={() => navigate("/minside/innstillinger/brukerprofil")}
          className="inline-flex items-center z-50 justify-center p-1 sm:p-2 -mr-2 text-sort-200 hover:text-tomat-500   focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white rounded-md    "
        >
          <span className="text-xs md:hidden">{firstName}</span>
          <svg
            className="h-4 w-4 text-red-500 md:hidden"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            {" "}
            <path stroke="none" d="M0 0h24v24H0z" />{" "}
            <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />{" "}
            <circle cx="12" cy="12" r="3" />
          </svg>
        </button>
      </div>
    </>
  )
}

function MyPageMenu() {
  const { accounts, instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const LogInOut = () => {
    if (isAuthenticated) navigate("/minside/innstillinger/Logout")
    if (!isAuthenticated) navigate("/minside/")
  }

  return (
    <>
      <div>
        {/* Inbox link and notification */}
        {/* Profile dropdown  */}
        <div className="z-50 relative">
          <button
            type="button"
            onClick={() => LogInOut()}
            className={MinSideButton}
            id="user-menu"
            aria-haspopup="true"
          >
            <span className="sr-only">Åpne Min Side</span>
            <span className={BadgeStyleTransparentRed}>{!isAuthenticated ? "Logg inn" : "Logg ut"}</span>
            {/* <a href="https://medlem.gartnerhallen.no/menystyring/meny/logginn.asp" className={BadgeStyleTransparentRed}>
              Logg inn
            </a> */}
          </button>
        </div>
      </div>
    </>
  )
}

export default function MainNavBar({ location }) {
  const { accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  // const navStateContext = useContext(NavStateContext)

  return (
    <>
      {/* <NavStateContext.Consumer> */
      /* {(navStateContext) => ( */}
      <nav className="bg-transparent">
        <div className="max-w-full">
          <div className="">
            <div className="relative flex items-center justify-between max-h-full">
              <div className="flex-none order-first flex-wrap px-2 sm:px-4 lg:px-8 z-50">
                <MegaMenuLogo location={location} />
              </div>
              <div className="flex-none order-last justify-self-end justify-end flex-wrap z-50">
                <div className="inline-block lg:px-6 z-50 absolute sm:static top-6 left-24">
                  <Search />
                </div>
                <div className="inline-block py-6 px-2 sm:px-4 lg:px-6 z-50">
                  {isAuthenticated ? (
                    <div className=" ">
                      <MyPageName location={location} />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="inline-block w-25 sm:px-4 lg:px-6 z-50">
                  <div className="block sm:block md:block lg:block xl:block">
                    <MyPageMenu />
                  </div>
                </div>
                {/* z index er satt spesielt i MegaMenu og skal da ikke settes her */}
                <div className="inline-block py-4 xl:py-6 px-4 sm:px-4 lg:px-6">
                  <MegaMenu location={location} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* )}
      </NavStateContext.Consumer> */}
    </>
  )
}
