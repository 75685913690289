import { Video } from "./Video"
import { TableWrapper, TableHeaderWrapper, TableHeader, TableBody, TableRow, TableCell } from "../Styling/Tables"
import { UnorderedHtmlListWrapper, OrderedHtmlListWrapper, ListItem } from "../Styling/Lists"
import MdxRadialChart from "./RadialChart"
import Blockquote from "../Styling/Blockquote"
import PrismCodeBlock from "../Styling/PrismCodeBlock"
import Code from "../Styling/Code"
import {
  MdxRenderTypographyH1,
  MdxRenderTypographyH2,
  MdxRenderTypographyH3,
  MdxRenderTypographyH4,
} from "../Styling/Headings"
import Factbox, { FactBoxItem } from "./Factbox"
import AccordionMenu, { AccordionMenuItem } from "./AccordionMenu"
import Parallax from "./Parallax"

import { TypographyLink } from "./Style"
import { TypographyParagraph, TypographyIngress } from "../Styling/Body"
import CloudinaryImage from "./CloudinaryImage"

// Styling of MXD Rendering
const MdxRenderTypographyP = (props) => <p className={TypographyParagraph} {...props} />
const MdxRenderTypographyIngress = (props) => <p className={TypographyIngress} {...props} />

const MdxRenderTypographyLink = (props) => <a className={TypographyLink} {...props} />

/**
 * Denne definerer all typografi, og importeres av MDX Render slik. (Husk å importere komponenten.)
 * @example <MDXProvider components={Typography}>
 */
const MdxComponent = {
  // Typography modifications
  p: MdxRenderTypographyP,
  h1: MdxRenderTypographyH1,
  h2: MdxRenderTypographyH2,
  h3: MdxRenderTypographyH3,
  h4: MdxRenderTypographyH4,

  ol: OrderedHtmlListWrapper,
  ul: UnorderedHtmlListWrapper,
  li: ListItem,

  // Design of tables
  table: TableWrapper,
  // Table header and column names
  thead: TableHeaderWrapper,
  th: TableHeader,
  // Table body
  tbody: TableBody,
  tr: TableRow,
  td: TableCell,

  blockquote: Blockquote,

  // Syntax highlighting med PrismJs
  inlineCode: Code,
  pre: PrismCodeBlock,
  // code: Not used

  // Web linkes in Contentful
  a: MdxRenderTypographyLink,
  // blockquote are dealt with by the MDX renderer for Youtube, Spotify, Instagram, Twitter,

  // Standard bilde links med bare url er støttet, men krever gatsby-image.
  // TODO: Cloudinary link style
  // Standard styling of images <Image>PublicID<Image>
  // Cloudinary: CloudinaryImageHandler
  Bilde: CloudinaryImage, // TODO: Lag eller gjenbruk bilde komponent.

  // Video linker
  Video: Video, // TODO: Oppdater med design og størrelse. Mulig vi må ha ny video komponent

  // Factbox
  Factbox: Factbox, // Factbox wrapper
  FactboxItem: FactBoxItem, // Factbox wrapper
  // AccordionMenu
  Accordion: AccordionMenu,
  AccordionItem: AccordionMenuItem,
  // RadialChart:
  RadialChart: MdxRadialChart,
  // Parallax
  Parallax: Parallax,
}

export default MdxComponent

export const MdxHeroIngressComponent = {
  // Typography modifications
  p: MdxRenderTypographyIngress,
}
