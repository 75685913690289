import ReactPlayer from "react-player/lazy"
// import { Triangel, triangelProps } from "../Common/Triangel"

// export const Pil = () => {
//   const triangelOne: triangelProps = {
//     viewBox: "100 100 220 220",
//     className: "fill-current stroke-current stroke-0  text-tomat-500 opacity-10",
//   }
//   return (
//     <div className="absolute left-6 md:left-64 xl:left-80 2xl:left-96 top-1 w-48 h-48 md:w-72 md:h-72 2xl:w-96 2xl:h-96 transform -rotate-3">
//       <Triangel {...triangelOne} />
//     </div>
//   )
// }

export type VideoProps = {
  url: string
  type: "card" | "hero"
}
/**
 * Display all types of videos with lazy loading of 3rd party apis
 * Base on https://github.com/cookpete/react-player
 * @todo Test if this gives better Google speed results. If not consider
 * https://www.npmjs.com/package/@justinribeiro/lite-youtube
 * https://www.npmjs.com/package/lite-youtube-embed
 * Frode har testet begge, men ingen virket i gatsby rett frem. lite-youtube har en react erstatning.
 *
 * Also, Consider adding custom icons for play/pause
 * @param url The Video url to display
 * @param type Size to use when displaying the video
 * @returns The JSX object
 */
export const Video = (props: VideoProps) => {
  let style = ""
  //  const faceBookAppID = process.env.FACEBOOK_APP_ID || ""

  switch (props.type) {
    case "card":
      style = "video border-none w-64 h-64"
      break
    case "hero":
      style = "relative h-56 sm:h-80 md:h-128 lg:h-128 w-full sm:w-full md:w-full lg:w-full md:mx-4 lg:mx-4"
      break
    default:
      style = "relative h-56 sm:h-80 md:h-128 lg:h-128 w-full sm:w-full md:w-full lg:w-full md:mx-4 lg:mx-4"
      break
  }
  return (
    <div className={style}>
      <ReactPlayer
        url={props.url}
        // light="true" // Consider adding
        width="100%"
        height="100%"
        className="absolute top-0 left-0"
        // playIcon="{Pil}"
        config={{
          youtube: {
            playerVars: { modestbranding: 1 },
          },
          // Add facebook konfig as needed, but for now, we are only getting Youtube vidoes. However we do support most videos
          // facebook: {
          //   appId: faceBookAppID,
          //   playerId: "4234234",
          // },
        }}
      />
    </div>
  )
}
