import { useStaticQuery, graphql } from "gatsby"

/**
 * Typedefinition of key value pairs
 * @param key ContentType type - As defined by contentful
 * @param value Slug = Main path to use without /
 */
export type MainPathsType = { [key: string]: string }

/**
 * @name AllMainPaths
 * @description Returns all paths as defined by contentful
 * @example const paths = AllMainPaths(). Remember to import import MainPath from "../components/Content/MainPaths"
 * @returns a collection of type mainPath
 * @author Frode Stenstrøm (Inspired by Younes Hajji)
 */
export const AllMainPaths = (): MainPathsType => {
  const data = useStaticQuery(graphql`
    {
      allContentfulMainPaths(filter: { node_locale: { eq: "nb-NO" } }) {
        nodes {
          slug
          contentType
        }
      }
    }
  `)

  const newItems: MainPathsType = {}

  data.allContentfulMainPaths.nodes.forEach((child) => {
    const key = child.contentType
    const value = child.slug
    newItems[key] = value
  })

  return newItems
}

/**
 * @name getMainPath
 * @description Returns one main path including / for the given key. See contentful for allowed keys
 * @returns one path like /blog
 * @author Frode Stenstrøm (Inspired by Younes Hajji)
 */
const getMainPath = (contentType) => {
  const data = AllMainPaths()

  return "/" + data[contentType]
}

export default getMainPath
