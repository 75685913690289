import {
  AuthError,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser"
import { loginRequest } from "./authConfig"

async function aquireAccessToken(account, inProgress, instance) {
  let accessToken = ""
  console.log("inProgress")
  console.log(inProgress)

  if (
    account &&
    (inProgress === InteractionStatus.None ||
      inProgress === InteractionStatus.HandleRedirect ||
      inProgress === InteractionStatus.Startup)
  ) {
    const request = {
      ...loginRequest,
      account: account,
    }
    await instance
      .acquireTokenSilent(request)
      .then(async (response) => {
        accessToken = response.accessToken
      })
      .catch(async (e) => {
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(request)
        }
      })
  }

  return accessToken
}

export const AuthToken = async (account, inProgress, instance) => {
  return await aquireAccessToken(account, inProgress, instance)
}
