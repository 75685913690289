import { useState, useEffect, useCallback } from "react"
import { TypographyAccordionMenuItem } from "./Style"
import { TypographyParagraph } from "./Body"

/**
 * @description This will create an Factbox menu.
 * The component expects to get the data needed to draw the component via props
 * The data in contentful is Accordion and AccordionItem.
 * @author Frode Stenstrøm
 */

/**
 * Factbox wrapper for the entire AccordionMenu
 * @param props AccordionMenu children
 */
const AccordionMenu = (props) => {
  const [children, setChildren] = useState([])
  const [openItem, setOpenItem] = useState()

  const items = props.children

  useEffect(() => {
    setChildren(() => (props.children.length ? props.children : [props.children]))
  }, [props.children])

  const clickHandler = useCallback((id) => {
    setOpenItem((prev) => {
      if (id === prev) {
        return ""
      } else {
        return id
      }
    })
  }, [])

  let keyId = 0
  const newItems = props.children.map((item) => ({
    ...item,
    key: keyId++,
  }))

  return (
    <>
      <div className="min-w-full mx-auto">
        {newItems.map((elt) => (
          <elt.type
            {...elt.props}
            key={elt.key}
            open={elt.key === openItem}
            onClick={() => clickHandler(elt.key)}
          ></elt.type>
        ))}
      </div>
    </>
  )
}

export default AccordionMenu
/**
 * Wrapper of AccordionMenuItem items.
 * @param props We expect to see title and body in that order
 */
export const AccordionMenuItem = (props) => {
  const title = props.title
  const body = props.children

  return (
    <>
      <div className="border-black border-t-2">
        <button type="button" className="" onClick={props.onClick}>
          <span className="sr-only">Åpne menypunkt</span>

          <span className={TypographyAccordionMenuItem}>{title}</span>

          {props.open ? (
            <svg
              className="h-6 w-6 content-end inline-block"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          ) : (
            <svg
              className="h-6 w-6 inline-block"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
            </svg>
          )}
        </button>

        {props.open ? (
          <div className="block">
            <div className={TypographyParagraph}>{body} </div>
          </div>
        ) : (
          <div className="hidden">
            <div className={TypographyParagraph}>{body} </div>
          </div>
        )}
      </div>
    </>
  )
}
