// import { strictEqual } from "assert"
import Footer from "../Footers/Footer"
// import NewsLetterSection from "../MarketingGDPR/NewsLetterSection"
import MainNavBar from "../Navbars/MainNavBar"

import { CoockieBanner } from "../MarketingGDPR/CoockieBanner"
import { useMemo, useState } from "react"
import { ConnectionContext } from "../CustomHooks/ConnectionContext"

/**
 * @name Layout
 * @description This component is the main layout for the entire site
 * @remarks This method is part of the {@link prosesspilotne.gartnerhallen#Footer | Footer components
 *
 * @param {[]} children
 * @returns {[]} returns an array representing the difference between the two arrays
 * @example <Layout> <p>Hellow World </p></Layout>
 *
 */

export default function Layout({ location, children }) {
  const [connection, setConnection] = useState({})
  const connectionProvider = useMemo(() => ({ connection, setConnection }), [connection, setConnection])
  return (
    <>
      <ConnectionContext.Provider value={connectionProvider}>
        <div>
          {/* Nav tag is set in the navbar */}
          <MainNavBar location={location} />

          {/* Header, article and main tags shall all be in the children */}
          <main>
            <div>{children}</div>
          </main>

          {/* <NewsLetterSection /> */}

          <Footer />

          <CoockieBanner />
        </div>
      </ConnectionContext.Provider>
    </>
  )
}
