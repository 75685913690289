import { RedirectRequest } from "@azure/msal-browser"

let clientId = ""
if (process.env.REACT_APP_AAD_B2C_APPLICATION_ID !== undefined && process.env.REACT_APP_AAD_B2C_APPLICATION_ID !== null)
  clientId = process.env.REACT_APP_AAD_B2C_APPLICATION_ID

/**
 * Config object to be passed to Msal on creation
 */
export const msalConfig = {
  auth: {
    clientId: clientId,
    authority:
      process.env.REACT_APP_AAD_B2C_INSTANCE +
      "" +
      process.env.REACT_APP_AAD_B2C_TENTANT +
      "/" +
      process.env.REACT_APP_AAD_B2C_SIGNINGPOLICY,
    knownAuthorities: [process.env.REACT_APP_AAD_B2C_TENTANT],
    validateAuthority: false,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
}

let b2cScopes = ""

if (
  process.env.REACT_APP_AAD_B2C_SCOPES_ACCESS_USER_DATA !== undefined &&
  process.env.REACT_APP_AAD_B2C_SCOPES_ACCESS_USER_DATA !== null
)
  b2cScopes = process.env.REACT_APP_AAD_B2C_SCOPES_ACCESS_USER_DATA

export const loginRequest: RedirectRequest = {
  scopes: [b2cScopes],
}
