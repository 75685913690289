import { useState } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router" // Find current page.
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies" // To initialize tracking.
import Cookies from "js-cookie"
import { TypographyLink } from "../Styling/Style"
import Toggle, { ToogleProps } from "../Graphics/Toggle"

/**
 * Method to set and remove a cookie.
 * @param props, expects to recieve 3 values; cookie, cookieExperationDays, consentExperationDays in that order
 * @param cookieExperationDays  Days the cookie is valid
 * @param consentExperationDays Days the concent to setting the cookie is valid
 * @param action Use On to set the cookie, and off to remove
 */
const ToggleCookie = (props: string[], action: "On" | "Off") => {
  const cookie = props[0]
  const cookieExperationDays = parseInt(props[1])
  const consentExperationDays = parseInt(props[2])
  switch (action) {
    case "On":
      Cookies.set(cookie, "true", { expires: cookieExperationDays })
      sessionStorage.setItem(cookie, JSON.stringify("true"))

      // Cookies.set("Samtykkedato", Date.now(), { expires: consentExperationDays })

      initializeAndTrack(useLocation)
      break
    case "Off":
      Cookies.set(cookie, "false", { expires: cookieExperationDays })
      sessionStorage.removeItem(cookie)

      // Cookies.set("Samtykkedato", Date.now(), { expires: consentExperationDays })

      break
  }
}

/**
 * @name DynamicsMarketingTracking
 * @returns The Dynamics Marketing tracking div need to track users in D365.
 * @description This is work in progress.. TODO
 */
// const DynamicsMarketingTracking = () => {
//   const abc = true
//   return (
//     <>
//       <div id="d7eSZi_260g4wRZA8jtlzLnt2AyMpsxIf9UTw3WAQBv0"></div>
//       <div
//         className="d365-mkt-config hidden"
//         data-website-id="7eSZi_260g4wRZA8jtlzLnt2AyMpsxIf9UTw3WAQBv0"
//         data-hostname="22c9a5938db440dc8e1eb29bead1f5f2.svc.dynamics.com"
//       ></div>
//     </>
//   )
// }

/**
 * @name CoockieBanner
 * @description Skaper en plagsom med viktig Cookie velger for besøkende
 * Den setter eller fjerner cookies.
 * Selve brukes av Cookies blir da satt av https://www.npmjs.com/package/gatsby-plugin-gdpr-cookies
 * Vi har cookies på 3 nivå:
 * 1. Nøvendige (Dynamics Marketing Forms)
 * 2. Analyse (Dynamics Marketing tracking og Google Analytics) gartnerhallen-gdpr-google-analytics
 * 3. Markedsføring (Dynamics Marketing nivå 5) (Facebook pixels ol), gartnerhallen-gdpr-google-tagmanager,  gartnerhallen-gdpr-facebook-pixel
 * Denne funksjonen kan også brukes av MinSide til å fjerne samtykke. Merk at Dynamics MArketing og denne må være i synk.
 * @author Frode Stenstrøm
 */
export const CoockieBanner = () => {
  const analyticsCookie = "gatsby-gdpr-google-analytics"
  const personalizationCookie = "gatsby-gdpr-google-tagmanager"
  const marketingCookie = "gatsby-gdpr-facebook-pixel"

  // Hide or show CookieBanner. Show once per month, or if consent has not been provided
  // Assume that the user has not consented:
  let defaultState = true
  const consentExperationDays = 90
  const cookieExperationDays = 730

  // Look for a current cookie to state last time of consent
  const ConsentGivenDate = Cookies.get("Samtykkedato")
  if (typeof ConsentGivenDate !== "undefined") {
    // We have consent, but is it more than 30 days old?
    const today = new Date()
    const consentExpirationDate = new Date().setDate(today.getDate() - consentExperationDays)

    if (consentExpirationDate < ConsentGivenDate) {
      // The selected time is less than 30 days from now
      defaultState = false
    } else if (consentExpirationDate > ConsentGivenDate) {
      // The selected time is more than 30 days from now
      defaultState = true
    } else {
      // -Exact- same timestamps.
      defaultState = true
    }
  }
  const [isHidden, setIsHidden] = useState(defaultState)

  const CookieNeeded: ToogleProps = {
    id: "Mandetory",
    default: true, // Default is and will always be true
    allowToggle: false,
    title: "Nødvendig",
    props: ["Mandetory", cookieExperationDays.toString(), consentExperationDays.toString()],
    action: ToggleCookie,
  }

  // Allow tracking (Google Analytics and Dynamics Marketing)
  const CookieAnalytics: ToogleProps = {
    id: "analyse",
    title: "Analyse",
    props: [analyticsCookie, cookieExperationDays.toString(), consentExperationDays.toString()],
    action: ToggleCookie,
  }

  // Check if cookie already has been set. If it has, keep it's settings, otherwise suggest a default seting.
  const currentAnalyticsCookie = Cookies.get(analyticsCookie)
  if (typeof currentAnalyticsCookie !== "undefined") {
    const currentAnalyticsCookieBool = currentAnalyticsCookie === "true"
    CookieAnalytics.default = currentAnalyticsCookieBool
  }
  // Not set, let us suggest TRUE
  else {
    CookieAnalytics.default = true
    Cookies.set(analyticsCookie, "true", { expires: cookieExperationDays })
  }

  // Allow Google Optimize via Tag manager
  const CookiePersonalization: ToogleProps = {
    id: "personalization",
    title: "Personlig tilpasning",
    props: [personalizationCookie, cookieExperationDays.toString(), consentExperationDays.toString()],
    action: ToggleCookie,
  }
  const currentPersonlizationCookie = Cookies.get(personalizationCookie)
  if (typeof currentPersonlizationCookie !== "undefined") {
    const currentPersonlizationCookieBool = currentPersonlizationCookie === "true"
    CookiePersonalization.default = currentPersonlizationCookieBool
  }
  // Not set, let us suggest FALSE
  else CookiePersonalization.default = false

  // Allow Pixel tracking (Facebook, LinkedIn and the like)
  const CookieMarketing: ToogleProps = {
    id: "marketing",
    title: "Markedsføring",
    props: [marketingCookie, cookieExperationDays.toString(), consentExperationDays.toString()],
    action: ToggleCookie,
  }
  // Check if cookie already has been set.
  const isCookieMarketingTrue = Cookies.get(marketingCookie)
  if (typeof isCookieMarketingTrue !== "undefined") {
    const currentMarkeringCookieBool = isCookieMarketingTrue === "true"
    CookieMarketing.default = currentMarkeringCookieBool
  }
  // Not set, let us suggest FALSE
  else CookieMarketing.default = false

  return (
    <>
      {isHidden ? (
        <>
          <div className="fixed inset-x-0 bottom-0 bg-transparent p-1 z-40">
            <div className="max-w-7xl mx-auto p-1 sm:p-4 lg:p-6">
              <div className="p-1 sm:p-4 md:p-4 lg:p-4 border-solid border-4 bg-lysgronn30-300 ">
                <h1 className="text-header24 py-2">Bruk av informasjonskapsler</h1>
                <div className="flex items-center justify-between flex-wrap">
                  <div className="flex-auto">
                    <div>
                      <p className="text-body14">
                        Dette nettstedet lagrer data som informasjonskapsler for å muliggjøre essensiell funksjonalitet
                        på nettstedet, analyse samt personlig tilpasning og markedsføring.
                      </p>
                      <p className="text-body14">
                        Du kan når som helst endre innstillingene dine eller godta standardinnstillingene.
                      </p>
                      <p className="text-body18 py-2">
                        Les mer i Gartnerhallens{" "}
                        <Link to="personvern" className={TypographyLink}>
                          personvernserklæring
                        </Link>
                      </p>
                      <div className="flex flex-row flex-wrap py-2 items-center">
                        {/* Accept level 1: Consent: Setts opp Marketing forms (Needed) */}
                        <div className="px-2">
                          <Toggle {...CookieNeeded} />
                        </div>
                        {/* Accept level 1: Consent: Sets up Dynamics Marketing tracking and  Google Analytics */}
                        <div className="px-2">
                          <Toggle {...CookieAnalytics} />
                        </div>
                        {/* Accept level 4: Enables Google Optimize and Google Tag manager */}
                        <div className="px-2">
                          <Toggle {...CookiePersonalization} />
                        </div>
                        {/* Accept level 5: Enables Pixels (Facebook, LinkedIn, Twitter) */}
                        <div className="px-2">
                          <Toggle {...CookieMarketing} />
                        </div>
                        <div className="p-2">
                          <button
                            onClick={() => {
                              // Default cookies are set already, so just update Consent.
                              Cookies.set("Samtykkedato", Date.now(), { expires: consentExperationDays })
                              initializeAndTrack(useLocation)
                              setIsHidden(!isHidden)
                            }}
                            className="inline-flex items-center text-button18 place-content-center font-normal text-black bg-white rounded-full border border-tomat-500 hover:text-white hover:bg-tomat-500 click:bg-red-900 click:text-white focus:bg-white focus:text-black focus:rounded-2xl disabled:text-white disabled:bg-sort-100 w-40 h-8"
                          >
                            Godta valgte
                          </button>
                        </div>
                        eller
                        <div className="p-2">
                          <button
                            onClick={() => {
                              Cookies.set(analyticsCookie, "true", { expires: consentExperationDays })
                              Cookies.set(personalizationCookie, "true", { expires: consentExperationDays })
                              Cookies.set(marketingCookie, "true", { expires: consentExperationDays })
                              Cookies.set("Samtykkedato", Date.now(), { expires: consentExperationDays })
                              initializeAndTrack(useLocation)

                              setIsHidden(!isHidden)
                            }}
                            // className={PrimaryBadgeClass}
                            className="inline-flex items-center text-button18 place-content-center font-normal text-black bg-white rounded-full border border-tomat-500 hover:text-white hover:bg-tomat-500 click:bg-red-900 click:text-white focus:bg-white focus:text-black focus:rounded-2xl disabled:text-white disabled:bg-sort-100 w-40 h-8"
                          >
                            Godta alle valg
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
